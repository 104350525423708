import React from "react";

import "./css/style.css";

import { Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import beach from "./photos/beachgif.gif";
import hotboi from "./photos/hotboi.JPEG";

const App = () => {
  return (
    <BrowserRouter>
      <div className="helper">
        {/* <div className="container"></div> */}
        <img className="beach" src={beach} alt=" beach " />
        <div className="links">
          <img src={hotboi} className="links-photo" />
          <div className="links-title">hot boi</div>
          <div>
            <Link
              className="links-button"
              to="https://truthsocial.com/@godofgods"
            >
              Angel Zeus
            </Link>
            <Link
              className="links-button"
              to="https://www.instagram.com/zeusfromthethrone/"
            >
              Zeus
            </Link>
            <Link
              className="links-button"
              to="https://www.instagram.com/dionysusfromthethrone/"
            >
              Dio
            </Link>
            <Link
              className="links-button"
              to="https://www.instagram.com/apollofromthethrone/"
            >
              Jupiter
            </Link>
            <Link className="links-button" to="https://twitter.com/ffavoidance">
              ff
            </Link>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
